<template lang="pug">
	ion-page
		a-navbar
		ion-content.ion-padding
			.w-vcenter
				div
					validation-observer(v-slot='{ handleSubmit }')
						form(@submit.prevent='handleSubmit(enter)')
							h1 Registrácia
							p.ion-margin-bottom.fs-normal Vyplň svoje údaje a registruj sa
							validation-provider(rules='required' v-slot='{ errors, validate }')
								ion-input.ion-margin-top(
									placeholder='Meno'
									:value='name'
									@ionInput='validate($event) && (name = $event.target.value)'
								)
								p.input-error {{ errors[0] }}

							validation-provider(rules='required' v-slot='{ errors, validate }')
								ion-input.ion-margin-top(
									placeholder='Priezvisko'
									:value='surname'
									@ionInput='validate($event) && (surname = $event.target.value)'
								)
								p.input-error {{ errors[0] }}
							validation-provider(rules='email|required' v-slot='{ errors, validate }')
								ion-input.ion-margin-top(
									placeholder='Email'
									:value='email'
									@ionInput='validate($event) && (email = $event.target.value)'
								)
								p.input-error {{ errors[0] }}
							validation-provider(rules='required' v-slot='{ errors, validate }')
								ion-input.ion-margin-top(
									placeholder='Heslo'
									type='password'
									:value='password'
									@ionInput='validate($event) && (password = $event.target.value)'
								)
								p.input-error {{ errors[0] }}


							validation-provider(:rules='{ required: { allowFalse: false } }' v-slot='{ errors, validate }')
								ion-item.ion-margin-top.ion-justify-content-center.ion-no-margin(lines="none")
									ion-checkbox(
										:value='terms'
										slot='start'
										mode='md'
										@ionChange='validate($event.detail.checked)  && (terms = $event.detail.checked)'
									)
									ion-label.test-test Súhlasím s&nbsp;
										a(href='https://drilleo.sk/themes/drilleo/assets/GDPR_OpenAcademy.pdf' target='_blank') podmienkami Drilleo
								p.input-error {{ errors[0] }}
							//p alebo...
							//div
							//  ion-button.outline(expand='block')
							//    .input-icon
							//      img(src='../assets/images/google-icon.svg')
							//    | Registrovať cez Google
							ion-button.ion-margin-top(expand='block' type='submit' color='green') Registrovať
							p.ion-margin-top Už máš účet?
								router-link.login-link(to='/prihlasenie') Prihlás sa.
		z-loader(v-if="loading")
		ion-footer
			small Vytvoril
			br
			img(:src='require("@/plugins/app/_theme/assets/images/openlab.svg")' width='100')
			br
			br
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

extend('email', {
	...email,
	message: 'Neplatný email.'
})
extend('required', {
	...required,
	message: 'Toto pole je povinné'
})

export default {

	components: {
		ValidationProvider,
		ValidationObserver,
		'a-navbar': () => import('@/plugins/app@components/navbar/a-navbar.vue'),
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue'),
	},

	data() {
		return {
			loading: false,
			name: '',
			surname: '',
			email: '',
			password: '',
			terms: false
		}
	},

	methods: {
		async enter() {
			try {
				this.loading = true
				await this.$store.dispatch('wAuth/signup', {
					name: this.name,
					surname: this.surname,
					email: this.email,
					password: this.password,
					role: 'student'
				})
				await this.$router.push({ name: 'Verify' })
			} catch (err) {
				await this.$wToast.error(err)
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
<style lang="sass" scoped>
ion-toolbar
	border-bottom: 5px solid #f7f7f9

.input-icon
	margin-right: 7px

.pointer
	cursor: pointer

.login-link
	cursor: pointer
	color: var(--a-black)
	font-weight: bold
	text-decoration: none
	margin-left: 4px

.input-error
	color: var(--a-red)
	margin-top: 0.25rem

.test-test
	z-index: 1000
</style>
