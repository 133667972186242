var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ion-page',[_c('a-navbar'),_c('ion-content',{staticClass:"ion-padding"},[_c('div',{staticClass:"w-vcenter"},[_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.enter)}}},[_c('h1',[_vm._v("Registrácia")]),_c('p',{staticClass:"ion-margin-bottom fs-normal"},[_vm._v("Vyplň svoje údaje a registruj sa")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-input',{staticClass:"ion-margin-top",attrs:{"placeholder":"Meno","value":_vm.name},on:{"ionInput":function($event){validate($event) && (_vm.name = $event.target.value)}}}),_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-input',{staticClass:"ion-margin-top",attrs:{"placeholder":"Priezvisko","value":_vm.surname},on:{"ionInput":function($event){validate($event) && (_vm.surname = $event.target.value)}}}),_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-input',{staticClass:"ion-margin-top",attrs:{"placeholder":"Email","value":_vm.email},on:{"ionInput":function($event){validate($event) && (_vm.email = $event.target.value)}}}),_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-input',{staticClass:"ion-margin-top",attrs:{"placeholder":"Heslo","type":"password","value":_vm.password},on:{"ionInput":function($event){validate($event) && (_vm.password = $event.target.value)}}}),_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-item',{staticClass:"ion-margin-top ion-justify-content-center ion-no-margin",attrs:{"lines":"none"}},[_c('ion-checkbox',{attrs:{"slot":"start","value":_vm.terms,"mode":"md"},on:{"ionChange":function($event){validate($event.detail.checked)  && (_vm.terms = $event.detail.checked)}},slot:"start"}),_c('ion-label',{staticClass:"test-test"},[_vm._v("Súhlasím s "),_c('a',{attrs:{"href":"https://drilleo.sk/themes/drilleo/assets/GDPR_OpenAcademy.pdf","target":"_blank"}},[_vm._v("podmienkami Drilleo")])])],1),_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ion-button',{staticClass:"ion-margin-top",attrs:{"expand":"block","type":"submit","color":"green"}},[_vm._v("Registrovať")]),_c('p',{staticClass:"ion-margin-top"},[_vm._v("Už máš účet?"),_c('router-link',{staticClass:"login-link",attrs:{"to":"/prihlasenie"}},[_vm._v("Prihlás sa.")])],1)],1)]}}])})],1)])]),(_vm.loading)?_c('z-loader'):_vm._e(),_c('ion-footer',[_c('small',[_vm._v("Vytvoril")]),_c('br'),_c('img',{attrs:{"src":require("@/plugins/app/_theme/assets/images/openlab.svg"),"width":"100"}}),_c('br'),_c('br')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }